/**
 * A wrapper around a protocol to transform name or version
 */
export class ProtocolTransformer {
    constructor(protocol, transformer) {
        this.protocol = protocol;
        this.transformer = transformer;
        // Noop
    }
    file(name, version, path) {
        const [transformedName, transformedVersion] = this.transformer(name, version);
        return this.protocol.file(transformedName, transformedVersion, path);
    }
    meta(name, version) {
        const [transformedName, transformedVersion] = this.transformer(name, version);
        return this.protocol.meta(transformedName, transformedVersion);
    }
}
